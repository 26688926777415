import http from '../../http/index';

export const fetchArticle = async (slug) => {
  try {
    return await http.get(`/api/content/articles/${slug}`);
  } catch {
    console.error(`Error occurred during fetching article: ${slug}`);
  }
};

export const fetchBlogArticles = async () => {
  try {
    return await http.get(`/api/content/articles`);
  } catch {
    console.error(`Error occurred during fetching blog articles`);
  }
};

import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

// Components
import MainHeaderContent from './headers-content/HomeContent';
import ArticleBlogContent from './headers-content/ArticleBlogContent';
import ArticleItemContent from './headers-content/ArticleItemContent';
import AboutUsContent from './headers-content/AboutUsContent';

// Utils
import paths from '../../utils/paths/paths';
import ContactContent from './headers-content/ContactContent';

function HeaderContent() {
  const { pathname } = useLocation();
  const { home, articleBlog, article, contact, aboutUs } = paths;

  let newsMatchLocation = useRouteMatch(`${article}`);

  return (
    <>
      {newsMatchLocation && (
        <ArticleItemContent articleId={newsMatchLocation.params.id} />
      )}
      {articleBlog === pathname && <ArticleBlogContent />}
      {home === pathname && <MainHeaderContent />}
      {contact === pathname && <ContactContent />}
      {aboutUs === pathname && <AboutUsContent />}
    </>
  );
}

export default HeaderContent;

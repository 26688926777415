import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

// Components
import Spinner from '../Spinner';
import SpinnerSuccessIcon from './SpinnerSuccessIcon';
import SpinnerErrorIcon from './SpinnerErrorIcon';

const Container = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${prop('theme.colors.t_white_9c')};
  z-index: 1;
  align-items: center;
  justify-content: center;
`;

const StyledSpinner = styled(Spinner)`
  width: 45px;
  height: 45px;
  border-width: 5px;
`;

const MessageContainer = styled.div`
  font-size: 0.9rem;
  font-family: 'Muli Bold', serif;
  margin-top: 20px;
`;

type Props = {
  className: string,
  spinnerIcon: string,
  message: string,
};

export const SPINNER_ICON = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};

const SPINNER_ICON_COMPONENT_MAP = {
  [SPINNER_ICON.IDLE]: null,
  [SPINNER_ICON.LOADING]: StyledSpinner,
  [SPINNER_ICON.SUCCESS]: SpinnerSuccessIcon,
  [SPINNER_ICON.ERROR]: SpinnerErrorIcon,
};

const SpinnerOverlay = ({ className, icon, message }: Props) => {
  const IconComponent = SPINNER_ICON_COMPONENT_MAP[icon];

  if (!IconComponent) {
    return null;
  }

  return (
    <Container className={className}>
      <IconComponent />
      <MessageContainer>{message}</MessageContainer>
    </Container>
  );
};

export default SpinnerOverlay;

import React from 'react';
import styled from 'styled-components';

// Components
import { LargeCaption, SimpleHeaderContent } from '../../Styled';

// Utils
import { media } from '../../../utils/style/media';

const LargeContactCaption = styled(LargeCaption)`
  font-size: 30px;

  ${media.customUp(800)`
      font-size: 40px;
    `};
`;

const AboutUsHeaderContent = styled(SimpleHeaderContent)`
  ${media.customUp(1700)`
    margin-left: 140px;
    `};
`;

const AboutUsContent = () => {
  return (
    <AboutUsHeaderContent>
      <LargeContactCaption> O nama... </LargeContactCaption>
    </AboutUsHeaderContent>
  );
};

export default AboutUsContent;

import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

const Container = styled.div`
  width: 30px;
  cursor: pointer;

  div {
    height: 4px;
    margin: 7px 0;
    background-color: black;
    transition: all 0.2s ease-in-out;
  }

  div:last-child {
    width: 20px;
  }

  ${ifProp(
    { active: true },
    css`
      div:first-child {
        transform: translateY(11px) rotate(45deg);
      }

      div:nth-child(2) {
        opacity: 0;
      }

      div:last-child {
        width: 30px;
        transform: translateY(-11px) rotate(-45deg);
      }
    `
  )};
`;

const Hamburger = ({ open, onClick }) => (
  <Container active={open} onClick={onClick}>
    <div />
    <div />
    <div />
  </Container>
);

export default Hamburger;

import React from 'react';
import styled from 'styled-components';

// Components
import { Circle } from '../../../components/Styled';

// Utils
import { dateSinceMessage } from '../../../utils/date/dateUtils';

const Container = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
`;

const NameDateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 17px;
`;
const CommentCircle = styled(Circle)`
  color: ${(prop) => prop.theme.colors.gray169};
`;

const NameContainer = styled.span`
  font-family: 'Muli Black', serif;
`;

const CommentTime = styled.span`
  font-family: 'Muli Regular', serif;
  color: ${(prop) => prop.theme.colors.gray169};
`;

const Comment = ({ comment: { name, content, date } }) => (
  <Container>
    <NameDateContainer>
      <NameContainer>{name}</NameContainer>
      <CommentCircle />
      <CommentTime>{dateSinceMessage(date)}</CommentTime>
    </NameDateContainer>
    <div>{content}</div>
  </Container>
);

export default Comment;

import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { media } from '../../utils/style/media';

const Container = styled.div`
  font-family: 'Muli Regular', serif;
  margin: 0 auto;
  width: 85vw;

  ${media.customUp('400')`
    width: 350px; 
  `};

  ${media.customUp('500')`
    width: 450px; 
  `};

  ${media.phoneUp`
    width: 550px; 
  `};

  ${media.tabletPortraitUp`
    width: 800px; 
  `};

  ${media.tabletLandscapeUp`
    width: 1000px; 
  `};
`;

const Title = styled.h2`
  font-family: 'Muli Black', serif;
  font-size: 1.8rem;
`;

const Italic = styled.p`
  font-weight: 900;
  font-size: 17px;
  font-style: italic;
  line-height: 27px;
`;

const ListElement = styled.li`
  font-weight: 900;
  font-size: 17px;
  line-height: 27px;
  line-height: 35px;

  ::before {
    margin-right: 10px;
    content: '• ';
    color: ${prop('theme.colors.orange')};
  }
`;

const List = styled.ul`
  list-style: none;
`;

const TextContent = styled.div``;

const AboutUs = () => (
  <Container>
    <Helmet>
      <title>O nama - Lokalna Alternativa Vrbas</title>
    </Helmet>
    <Title>Ko smo mi i za šta se zalažemo?</Title>
    <TextContent>
      <Italic>
        Mi smo grupa građana okupljena oko zajedničkih vrednosti sa idejom da udruženim snagama pokrenemo pozitivne
        promene u društvenoj zajednici kojoj pripadamo. Definisali smo zajedničke ciljeve i principe po kojima delujemo
        i osnovali udruženje građana “Lokalna alternativa” sa sedištem u Vrbasu.
      </Italic>
      <p>
        Bavimo se pitanjima od interesa za građane opštine Vrbas, pokrećemo javni dijalog u cilju iznalaženja najboljih
        rešenja za probleme koji pogađaju našu zajednicu i iniciramo njihovo rešavanje.
      </p>
      <p>
        U Skupštini opštine Vrbas imamo svoje predstavnike od svog osnivanja. Kritičari smo aktuelne vlasti. Koristimo
        svaku priliku da rad lokalnog parlamenta približimo građanima i da se u njemu čuje glas običnih ljudi.
      </p>
      <p> Osnovni ciljevi su nam: </p>
      <List>
        <ListElement>Podizanje nivoa svesti o značaju građanskog sudelovanja </ListElement>
        <ListElement>Aktivnije učešće građana u procesu donošenja odluka od zajedničkog interesa </ListElement>
        <ListElement>Kontrola upravljanja javnim resursima</ListElement>
        <ListElement>Zaštita i unapređenje slobode govora i javnog informisanja</ListElement>
      </List>

      <p>
        Ukazujemo na nepravilnosti u radu organa javne vlasti, insistiramo na učešću javnosti u radu lokalne samouprave,
        državne uprave, javnih preduzeća i ustanova. Na taj način utičemo na ostvarivanje javnog interesa.
      </p>
      <Italic>
        Štitimo prava građana pred organima vlasti, komunalnim i drugim javnim preduzećima. Borimo se protiv korupcije;
        stranačke, rasne i svake druge vrste diskriminacije.
      </Italic>
      <p>
        Na tom putu sarađujemo sa svim pojednicima i organizacijama koje poštuju slične principe i dele iste vrednosti.
      </p>
      <p>
        Pozivamo Vas da jasno iskažete svoj stav i aktivno se uključite u borbu za uređeno i prosperitetno društvo
        jednakih mogućnosti za sve.
      </p>
    </TextContent>
  </Container>
);

export default AboutUs;

import React from 'react';
import styled from 'styled-components';

// Assets
import { ReactComponent as LogoFacebook } from '../../assets/images/logo-social-facebook.svg';
import { ReactComponent as LogoLinkedIn } from '../../assets/images/logo-social-linkedIn.svg';
import { ReactComponent as LogoReddit } from '../../assets/images/logo-social-reddit.svg';
import { ReactComponent as LogoX } from '../../assets/images/logo-social-x.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 80px;
`;

const Message = styled.p`
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 900;
  margin-right: 10px;
  padding-bottom: 5px;
`;

const ShareButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function ShareArticle({ url, title }) {
  const shareOnFacebook = () => {
    const encodedUrl = encodeURIComponent(url);
    const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
    window.open(fbUrl, '_blank', 'noopener,noreferrer');
  };

  const shareOnX = () => {
    const encodedUrl = encodeURIComponent(url);
    const encodedTitle = encodeURIComponent(title);
    const xUrl = `https://x.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`;
    window.open(xUrl, '_blank', 'noopener,noreferrer');
  };

  const shareOnReddit = () => {
    const encodedUrl = encodeURIComponent(url);
    const encodedTitle = encodeURIComponent(title);
    const redditUrl = `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`;
    window.open(redditUrl, '_blank', 'noopener,noreferrer');
  };

  const shareOnLinkedIn = () => {
    const encodedUrl = encodeURIComponent(url);
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
    window.open(linkedInUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <Container>
      <Message>podeli vesti:</Message>

      <ShareButton onClick={shareOnFacebook}>
        <LogoFacebook width={40} height={40} />
      </ShareButton>

      <ShareButton onClick={shareOnX}>
        <LogoX width={40} height={40} />
      </ShareButton>

      <ShareButton onClick={shareOnLinkedIn}>
        <LogoLinkedIn width={40} height={40} />
      </ShareButton>

      <ShareButton onClick={shareOnReddit}>
        <LogoReddit width={40} height={40} />
      </ShareButton>
    </Container>
  );
}

export default ShareArticle;

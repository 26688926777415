import React from 'react';
import styled from 'styled-components';

// Utils
import { formatStringDate } from '../../utils/date/dateUtils';

const Container = styled.div`
  display: flex;
  height: 40%;
  margin-top: auto;
  padding: 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
`;

const Content = styled.div`
  margin-top: auto;
`;

const ArticleDate = styled.p`
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 80%;
  margin-bottom: 3%;
  font-family: 'Roboto Medium', serif;
`;

const ArticleTitle = styled.h3`
  font-size: 130%;
  margin: 0;
  font-family: 'Roboto Bold', serif;
  color: white;
  letter-spacing: 1px;

  a {
    color: #fff;
    text-decoration: none;
  }
`;

const ArticleBlogInfo = ({ title, date }) => (
  <Container>
    <Content>
      <ArticleDate>{date && formatStringDate(date)}</ArticleDate>
      <ArticleTitle>{title}</ArticleTitle>
    </Content>
  </Container>
);

export default ArticleBlogInfo;

import React from 'react';
import { withRouter } from 'react-router-dom';

// Components
import MainHeader from './MainHeader';
import SimpleHeader from './SimpleHeader';

// Utils
import paths from '../../utils/paths/paths';

const Header = ({ location }) => {
  const isMainPage = location.pathname.includes(paths.home);

  return isMainPage ? <MainHeader /> : <SimpleHeader />;
};

export default withRouter(Header);

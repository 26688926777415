import React from 'react';
import styled from 'styled-components';

// Components
import Comment from './Comment';

const ContainerComments = styled.div`
  margin-bottom: 40px;
`;

const CommentsNumber = styled.label`
  display: block;
  padding: 20px 0;
  font-weight: bold;
  font-size: 22pt;
  border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
  font-family: 'Muli Black', serif;
`;

const CommentList = ({ comments }) => {
  const numberOfCommnets = comments ? comments.length : 0;

  return (
    <ContainerComments>
      <CommentsNumber>{`Komentari (${numberOfCommnets})`}</CommentsNumber>
      {comments &&
        comments.map((comment, index) => (
          <Comment key={index} comment={comment} />
        ))}
    </ContainerComments>
  );
};

export default CommentList;

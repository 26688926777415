import React from 'react';
import styled from 'styled-components';

// Components
import {
  LargeCaption,
  TransparentButton,
  SmallCaption,
  LinkNoUnderLine,
  Description,
} from '../../Styled';

// Utils
import { media } from '../../../utils/style/media';
import { getMessage } from '../../../i18n/i18n';
import paths from '../../../utils/paths/paths';
import { TEXT_ABOUT_ORGANIZATION } from '../../../utils/constants';

const FindMoreButton = styled(TransparentButton)`
  padding: 0px 15px;
  display: none;

  ${media.customUp(400)`
  display: flex ;
  `};

  span {
    display: block;
    font-size: 22px;
    font-weight: 100;
    margin: 0px 0px 4px 15px;
  }
`;

const Motto = styled(LargeCaption)`
  font-size: 35px;
`;

const MainHeaderFirstMotto = styled(Motto)``;
const MainHeaderSecondMotto = styled(Motto)`
  display: none;

  ${media.customUp(720)`
  display: block;
  `};
`;

const MainHeaderTextContent = styled.div`
  display: flex;

  flex-direction: column;
  gap: 20px;
  max-width: 500px;

  ${media.phoneUp`
    max-width: 700px;
  `};
`;

function MainHeaderContent() {
  return (
    <MainHeaderTextContent>
      <SmallCaption>{getMessage('lokal.header.name')}</SmallCaption>
      <div>
        <MainHeaderFirstMotto>
          {getMessage('lokal.header.home.motto.one')}
        </MainHeaderFirstMotto>
        <MainHeaderSecondMotto>
          {getMessage('lokal.header.home.motto.two')}
        </MainHeaderSecondMotto>
      </div>
      <Description>{TEXT_ABOUT_ORGANIZATION}</Description>
      <LinkNoUnderLine to={paths.aboutUs}>
        <FindMoreButton>
          Saznaj više <span> &#10141; </span>
        </FindMoreButton>
      </LinkNoUnderLine>
    </MainHeaderTextContent>
  );
}

export default MainHeaderContent;

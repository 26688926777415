//paths
import paths from './paths';

//components
import AboutUs from '../../pages/about/AboutUs.jsx';
import ArticleBlog from '../../pages/article-blog/ArticleBlog.jsx';
import Article from '../../pages/article/Article';
import ContactPage from '../../pages/contact/ContactPage';
import Home from './../../pages/home/Home';

const pathComponentItems = [
  {
    path: paths.home,
    component: Home,
  },
  {
    path: paths.article,
    component: Article,
  },
  {
    path: paths.articleBlog,
    component: ArticleBlog,
  },
  {
    path: paths.aboutUs,
    component: AboutUs,
  },
  {
    path: paths.contact,
    component: ContactPage,
  },
];

export default pathComponentItems;

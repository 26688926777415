import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components';
import _isFunction from 'lodash/isFunction';
import { prop } from 'styled-tools';

// Components
import { OrangeButton } from '../../components/Styled';
import TextArea from '../../components/final-form/TextArea';
import Input from '../../components/final-form/Input';
import SpinnerOverlay, {
  SPINNER_ICON,
} from '../../components/spinner-overlay/SpinnerOverlay';

// Assets
import contactImg from '../../assets/images/contact.jpg';

// Utils
import {
  combineValidators,
  required,
  validEmail,
} from '../../utils/validators';
import { media } from '../../utils/style/media';

const StyledForm = styled.form`
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 1rem;

  ${media.customUp(800)`
    padding: 35px 50px;
  `};
`;

const SubmitContainer = styled.div`
  display: flex;
  height: 10%;
  margin-top: 7%;
  align-items: center;
`;

const SubmitButton = styled(OrangeButton)``;

const Container = styled.section`
  width: 95vw;
  margin: 0 auto;
  max-width: 900px;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  ${media.phoneUp`
    flex-direction: row;
  `};
`;

const Image = styled.img`
  ${media.phoneUp`
    flex: 1;
    width: 50%;
  `};
`;

const FormContainer = styled.div`
  position: relative;
  background-color: ${prop('theme.colors.whisper')};

  ${media.phoneUp`
    flex: 1;
    width: 50%;
  `};
`;

const ContactInput = styled(Input)`
  height: 35px;
  margin-bottom: 20px;
  padding: 20px 15px;
  border: 2px solid ${(props) => props.theme.colors.gray169};
  border-radius: 3px;

  ::placeholder {
    color: ${(props) => props.theme.colors.gray169};
    font-weight: 600;
  }
`;

const ContactTextArea = styled(TextArea)`
  height: 135px;
  resize: none;
  padding: 10px;
  border: 2px solid ${(props) => props.theme.colors.gray169};
  border-radius: 3px;

  ::placeholder {
    color: ${(props) => props.theme.colors.gray169};
    font-weight: 600;
  }
`;

const ContactPage = () => {
  const [spinnerData, setSpinnerData] = useState({
    icon: SPINNER_ICON.IDLE,
    message: '',
  });

  const updateSpinnerData = (icon, message) =>
    setSpinnerData({ icon, message });

  const hideSpinner = (callback) => {
    setTimeout(() => {
      updateSpinnerData(SPINNER_ICON.IDLE, '');
      if (_isFunction(callback)) {
        callback();
      }
    }, 3000);
  };

  const handleSubmit = async (values, form) => {
    updateSpinnerData(SPINNER_ICON.LOADING, '');

    const response = await fetch('/api/content/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });

    const { status, statusText } = response;

    const error = status !== 200;

    if (error) {
      console.error("Can't send contact message: ", statusText);
      updateSpinnerData(SPINNER_ICON.ERROR, 'Greska, pokusajte ponovo.');
      return;
    }

    updateSpinnerData(SPINNER_ICON.SUCCESS, 'Komentar uspesno poslat.');
    hideSpinner(error ? null : form.reset);
  };

  return (
    <Container>
      <Helmet title="Kontak - Lokalna Alternativa Vrbas" />

      <MainContent>
        <Image src={contactImg} alt="contact image" />
        <FormContainer>
          <SpinnerOverlay
            icon={spinnerData.icon}
            message={spinnerData.message}
          />
          <Form
            onSubmit={handleSubmit}
            subscription={{ touched: true, submitting: true }}
            render={({ handleSubmit, error, errors }, meta) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <Field
                    name="name"
                    placeHolder="Ime i prezime"
                    validate={required('Ime i prezime je obavezno')}
                    autoComplete="off"
                    component={ContactInput}
                  />
                  <Field
                    name="mail"
                    placeHolder="Vaša Email adresa"
                    validate={combineValidators(
                      required('Email adresa je obavezna'),
                      validEmail('Mejl nije validan')
                    )}
                    autoComplete="off"
                    component={ContactInput}
                  />
                  <Field
                    name="title"
                    placeHolder="Naslov poruke"
                    validate={required('Naslov poruke je obavezan')}
                    autoComplete="off"
                    component={ContactInput}
                  />

                  <Field
                    name="content"
                    placeHolder="Teskt poruke"
                    validate={required('Tekst poruke obavezan')}
                    autoComplete="off"
                    component={ContactTextArea}
                  />
                  <SubmitContainer>
                    <SubmitButton type="submit">posalji </SubmitButton>
                  </SubmitContainer>
                </StyledForm>
              );
            }}
          />
        </FormContainer>
      </MainContent>
    </Container>
  );
};

export default ContactPage;

const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

export const required = (message) => (value) => (value ? undefined : message);

export const validEmail = (message) => (value) =>
  emailRegex.test(value) ? undefined : message;

export const combineValidators = (...validators) => (value) => {
  for (let validator of validators) {
    const error = validator(value);
    if (error) {
      return error;
    }
  }
  return undefined;
};

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import { useArticle } from '../../../hooks/useArticle';

// Components
import { OrangeButton as SubmitButton } from './../../../components/Styled';
import SpinnerOverlay, {
  SPINNER_ICON,
} from '../../../components/spinner-overlay/SpinnerOverlay';

const Form = styled.form`
  position: relative;
  width: 100%;
`;

const BaseInput = css`
  width: 100%;
  border: 1px solid
    ${ifProp('valid', prop('theme.colors.lightGray'), prop('theme.colors.red'))};
  font-size: 0.8rem;
  font-family: 'Muli Regular', serif;
  box-sizing: border-box;
  border-radius: 7px;

  ::placeholder {
    color: ${ifProp(
      'valid',
      prop('theme.colors.gray169'),
      prop('theme.colors.red')
    )};
  }
`;

const TextArea = styled.textarea`
  height: 200px;
  margin: 20px 0 15px 0;
  padding: 20px;
  resize: none;
  ${BaseInput};
`;

const Input = styled.input`
  padding: 20px;
  margin: 20px 0 15px 0;
  ${BaseInput};
`;

const Title = styled.label`
  display: block;
  margin-top: 30px;
  font-size: 19pt;
  font-family: 'Muli Black', serif;
`;

const SubmitContainer = styled.div`
  display: flex;
  padding: 20px 0 40px 0;
`;

const initialCommentFormState = {
  name: '',
  content: '',
};

const CommentForm = ({ articleId }) => {
  const { refetchArticle } = useArticle();
  const [state, setState] = useState({ ...initialCommentFormState });
  const [validation, setValidation] = useState({ ...initialCommentFormState });

  const [overlayState, setOverlayState] = useState({
    icon: SPINNER_ICON.IDLE,
    message: null,
  });

  const hideSpinnerOverlay = () => {
    setTimeout(
      () => setOverlayState({ icon: SPINNER_ICON.IDLE, message: null }),
      3000
    );
  };

  const showError = (exception) => {
    console.error("Can't send contact message: ", exception);
    setOverlayState({
      icon: SPINNER_ICON.ERROR,
      message: 'Doslo je do greske, pokusajte ponovo',
    });
  };

  const handleChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value,
    });

    setValidation({
      ...validation,
      [name]: !value,
    });
  };

  const checkValidation = () => {
    const fieldValidation = {};

    Object.keys(state).forEach((key) => {
      if (!state[key]) {
        fieldValidation[key] = true;
      }
    });
    setValidation(fieldValidation);

    return Object.keys(fieldValidation).length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (checkValidation()) {
      return;
    }

    const { name, content } = state;

    try {
      setOverlayState({ icon: SPINNER_ICON.LOADING, message: null });

      const response = await fetch(
        `/api/content/articles/${articleId}/comments`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, content }),
        }
      );

      const { status, statusText } = response;

      if (status === 404 || status === 500) {
        showError(statusText);
      } else {
        setState({ ...initialCommentFormState });
        setValidation({ ...initialCommentFormState });
        setOverlayState({
          icon: SPINNER_ICON.SUCCESS,
          message: 'Komentar uspesno poslat. Bice objavljen nakon provere.',
        });

        refetchArticle();
      }
    } catch (exception) {
      showError(exception);
    }

    hideSpinnerOverlay();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <SpinnerOverlay icon={overlayState.icon} message={overlayState.message} />
      <Title>Ostavi komentar</Title>
      <Input
        name="name"
        type="text"
        value={state.name}
        valid={!validation.name}
        onChange={handleChange}
        placeholder={`Ime ${validation.name && 'je obavezno'}`}
      />
      <TextArea
        name="content"
        value={state.content}
        onChange={handleChange}
        valid={!validation.content}
        placeholder={`Tekst komentara ${validation.content && 'je obavezan'}`}
      />
      <SubmitContainer>
        <SubmitButton type="submit"> Pošalji </SubmitButton>
      </SubmitContainer>
    </Form>
  );
};

export default CommentForm;

import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

// Assets
import headerBackgroundImg from '../../assets/images/header.png';
import { ReactComponent as LogoMotto } from '../../assets/images/logo-motto.svg';
import { ReactComponent as LargeOrganizationLogo } from '../../assets/images/logo-organization-large.svg';

// Components
import HeaderContent from './HeaderContent';

// Utils
import { media } from '../../utils/style/media';

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  margin-top: ${prop('theme.navbar.smallHeight')};
  background: url(${headerBackgroundImg}) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
  padding: ${prop('theme.page.mediumPadding')};
  height: 350px;

  position: relative;

  ${media.phoneUp`
    margin-top: ${prop('theme.navbar.height')};
  `};

  ${media.customUp(1100)`
    padding: ${prop('theme.page.padding')};
    height: 400px;
  `};
`;

const LogoOrganizationContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  margin-right: 10px;
  display: none;

  ${media.tabletPortraitUp`
  display: block;
    flex: 1;
  `};

  ${media.tabletLandscapeUp`
    margin-right: 30px;
  `};
`;

const LogoOrganizationStyled = styled(LargeOrganizationLogo)`
  width: 100px;

  ${media.customUp(400)`
    width: 130px;
  `};

  ${media.customUp(800)`
    width: 135px;
    margin-right: 10px;
  `};
`;

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  margin: unset;
  color: ${(props) => props.theme.colors.white};
  align-items: center;

  ${media.customUp(1700)`
    justify-content:center;
    margin-right:350px;
  `};
`;

const LogoMottoContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 30px;
  overflow: hidden;

  ${media.phoneUp`
    position: unset;
    `};

  ${media.customUp(800)`
    order: 2;
    overflow: unset; 
    margin-right: 0;
    flex: 3;
  `};
`;

const LogoMottoStyled = styled(LogoMotto)`
  height: 180px;
  width: 180px;
  margin-right: -70px;
  opacity: 0.2;
  transform: rotate(-30deg);
  overflow: hidden;

  ${media.phoneUp`
    margin-right: 0;
    opacity: 0.5;
  `};

  ${media.customUp(800)`
    margin-left: auto;
    margin-right: 20px;
  `};

  ${media.customUp(1100)`
    height: 200px;
    width: 200px;
  `};
`;

const MainHeader = () => (
  <Container>
    <LogoOrganizationContainer>
      <LogoOrganizationStyled />
    </LogoOrganizationContainer>
    <TextContainer>
      <HeaderContent />
    </TextContainer>
    <LogoMottoContainer>
      <LogoMottoStyled />
    </LogoMottoContainer>
  </Container>
);

export default MainHeader;

import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Components
import ArticleBlogItem from './../../components/article/ArticleBlogItem';
import { OrangeButton as AllNewsButton } from './../../components/Styled';

// Utils
import { media } from '../../utils/style/media';

// Actions
import { fetchBlogArticles } from '../../commands/article/actions';

// Paths
import paths from '../../utils/paths/paths';

const Title = styled.p`
  width: 100%;
  margin: 20px 0 10px 0;
  font-size: 2em;
  font-family: 'Roboto Bold', serif;
`;

const Container = styled.section`
  display: grid; 
  grid-template-columns: 90vw;
  grid-template-rows: 100px 90vw; 
  grid-auto-rows: 90vw;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  justify-content: center;
  justify-items: center;
  margin-bottom: 20px;

  ${media.phoneUp`
    grid-template-columns: 45vw 45vw;
    grid-template-rows: 100px 45vw; 
    grid-auto-rows: 45vw; 
    grid-column-gap: 20px;

    & > :nth-child(2) { 
      grid-column: 1 / 3; 
      grid-row: auto;
    }

    
    ${Title} {
      grid-column: 1 / 3;  
      width: 100%;
    }
  `} 

  ${media.customUp(800)`
    grid-template-columns: repeat(2, 350px);
    grid-template-rows: 100px 350px; 
    grid-auto-columns: 350px;
    grid-auto-rows: 350px; 

    & > :nth-child(2) {
      grid-column: 1 / 3; 
      grid-row: auto;
    }

    ${Title} {
      grid-column: 1 / 3;
    }
  `}
  
  ${media.tabletLandscapeUp`
    grid-template-columns: repeat(3, 350px);
    grid-template-rows: 100px 350px; 
    grid-auto-columns: 350px;
    grid-auto-rows: 350px; 

    & > :nth-child(2) {
      grid-column: 1 / 3;
      grid-row: auto;
    }
    
    ${Title} {
      grid-column: 1 / 4;
    }
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 70px;
`;

const FirstGridColumn = styled.div`
  height: 100px;
  grid-column: 1 / -1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: baseline;
`;

export default function Home() {
  const [articles, setArticles] = useState([]);
  const [isOddNumberOfArticles, setIsOddNumberOfArticles] = useState(false);
  const history = useHistory();

  const getArticles = async () => {
    const fetchedArticles = await fetchBlogArticles();
    setArticles(fetchedArticles);
  };

  useEffect(() => {
    getArticles();
  }, []);

  useEffect(
    () => {
      const handleNumberOfArticles = () => {
        if (window.innerWidth < 1200) setIsOddNumberOfArticles(true);
        if (window.innerWidth >= 1200) setIsOddNumberOfArticles(false);
      };

      handleNumberOfArticles();

      window.addEventListener('resize', handleNumberOfArticles);

      return () => window.removeEventListener('resize', handleNumberOfArticles);
    },
    [isOddNumberOfArticles]
  );

  const handleClickAllNews = useCallback(
    () => {
      history.push(paths.articleBlog);
    },
    [history]
  );

  return (
    <>
      <Container>
        <Helmet>
          <title>Lokalna Alternativa Vrbas</title>
        </Helmet>

        <FirstGridColumn>
          <Title>Poslednje vesti</Title>
        </FirstGridColumn>

        {articles.slice(0, isOddNumberOfArticles ? 7 : 8).map((article) => (
          <ArticleBlogItem key={article.id} article={article} />
        ))}
      </Container>
      <ButtonWrapper>
        <AllNewsButton onClick={handleClickAllNews}>sve vesti</AllNewsButton>
      </ButtonWrapper>
    </>
  );
}

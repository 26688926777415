import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import _ from 'lodash';

// Components
import Header from './components/header/Header.jsx';
import Footer from './components/footer/Footer.jsx';
import NavBar from './components/nav-bar/NavBar';
import ScrollToTop from './components/ScrollToTop';

// Context
import GlobalContext from './context/GlobalContext';
import ArticleContext from './context/ArticleContext.jsx';

// Utils
import { theme } from './utils/style/theme';
import paths from './utils/paths/paths';
import pathComponentItems from './utils/paths/pathComponentItems';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-family: 'Roboto Regular';
  }
`;

const GridContainer = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const App = () => {
  const routes = _.map(pathComponentItems, (item, index) => (
    <Route key={index} path={item.path} component={item.component} />
  ));

  return (
    <BrowserRouter>
      <HelmetProvider>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          <GlobalContext>
            <ArticleContext>
              <NavBar />
              <GridContainer>
                <Header />
                <Switch>
                  {routes}
                  <Redirect to={paths.home} />
                </Switch>
                <Footer />
              </GridContainer>
              <GlobalStyle />
            </ArticleContext>
          </GlobalContext>
        </ThemeProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
};

export default App;

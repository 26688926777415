import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { prop } from 'styled-tools';

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgb(205, 208, 229);
`;

const Submit = styled.input`
  font-weight: bold;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white};
`;

const LinkNoUnderLine = styled(Link)`
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;
`;

const Circle = styled.span`
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 0 8px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.gray};
`;

const SmallCaption = styled.p`
  font-family: 'Roboto Medium', serif;
  text-transform: uppercase;
  margin: 0;
  font-size: 17px;
  letter-spacing: 3px;
`;

const LargeCaption = styled.p`
  font-weight: 500;
  margin: 0;
  font-family: 'Roboto Black', serif;
  letter-spacing: 1px;
`;

const Description = styled.dl`
  max-width: 800px;
  font-size: 16px;
  margin: 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-around;

  text-transform: uppercase;
  padding: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  height: 50px;
  min-width: 170px;
  width: fit-content;
  height: 50px;
`;

const OrangeButton = styled(Button)`
  background-color: ${prop('theme.colors.orange')};
  color: ${prop('theme.colors.white')};
`;

const TransparentButton = styled(Button)`
  background-color: ${prop('theme.colors.transparent')};
  color: ${prop('theme.colors.white')};
  border: 2px solid ${prop('theme.colors.white')};
`;

const SimpleHeaderContent = styled.div`
  max-width: 1100px;
  width: 100%;
`;

export {
  Line,
  Submit,
  SimpleHeaderContent,
  OrangeButton,
  TransparentButton,
  LargeCaption,
  SmallCaption,
  Circle,
  Description,
  LinkNoUnderLine,
};

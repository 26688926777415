import React from 'react';
import styled from 'styled-components';

// Components
import { LargeCaption, SmallCaption, SimpleHeaderContent } from '../../Styled';

// Utils
import { media } from '../../../utils/style/media';

const LargeContactCaption = styled(LargeCaption)`
  font-size: 30px;

  ${media.customUp(800)`
      font-size: 40px;
    `};
`;

const SmallContactCaption = styled(SmallCaption)`
  font-size: 12px;

  ${media.customUp(800)`
     font-size: 17px;;
    `};
`;

const ContactHeaderContent = styled(SimpleHeaderContent)`
  ${media.customUp(1700)`
    margin-left: 220px;
    `};
`;

const ContactContent = () => {
  return (
    <ContactHeaderContent>
      <LargeContactCaption>Imate pitanja?</LargeContactCaption>
      <SmallContactCaption>
        budite slobodni da nas kontaktirate
      </SmallContactCaption>
    </ContactHeaderContent>
  );
};

export default ContactContent;

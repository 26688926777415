import React, { useState, createContext } from 'react';

// Actions
import { fetchArticle } from './../commands/article/actions';

export const ContextArticle = createContext();

const ArticleContext = ({ children }) => {
  const [newsArticle, setNewsArticle] = useState({});
  const [slug, setSlug] = useState('');

  const getArticle = async (slug) => {
    setNewsArticle({});
    setNewsArticle(await fetchArticle(slug));
    setSlug(slug);
  };

  const refetchArticle = () => getArticle(slug);

  const context = {
    newsArticle,
    getArticle,
    refetchArticle,
  };

  return (
    <ContextArticle.Provider value={context}>
      {children}
    </ContextArticle.Provider>
  );
};

export default ArticleContext;

import colors from '../utils/style/colors';

export const categoryOptions = {
  VRBAS: {
    textKey: 'lokal.article.category.vrbas',
    color: colors.white,
    backgroundColor: colors.orange,
  },
  PROVINCE: {
    textKey: 'lokal.article.category.province',
    color: colors.black,
    backgroundColor: colors.white,
  },
  REGION: {
    textKey: 'lokal.article.category.region',
    color: colors.black,
    backgroundColor: colors.white,
  },
  REPUBLIC: {
    textKey: 'lokal.article.category.republic',
    color: colors.black,
    backgroundColor: colors.white,
  },
};

import { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';

export const BaseInput = css`
  width: 100%;
  border: 1px solid ${ifProp('valid', prop('theme.colors.lightGray'), prop('theme.colors.red'))};
  font-size: 0.8rem;
  font-family: 'Muli Regular', serif;
  box-sizing: border-box;

  ::placeholder {
    color: ${ifProp('valid', prop('theme.colors.gray169'), prop('theme.colors.red'))};
  }
`;

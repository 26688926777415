import paths from '../utils/paths/paths';

const navBarData = [
  {
    name: 'Početna',
    path: paths.home,
  },
  {
    name: 'Vesti',
    path: paths.articleBlog,
  },
  {
    name: 'O nama',
    path: paths.aboutUs,
  },
  {
    name: 'Kontakt',
    path: paths.contact,
  },
];

export default navBarData;

import React, { useState, createContext } from 'react';

export const Context = createContext();

const GlobalContext = ({ children }) => {
  const [message, setMessage] = useState(null);

  const showMessage = msg => {
    setMessage(msg);
    setTimeout(() => setMessage(null), 3000);
  };

  const context = {
    message,
    showMessage,
  };

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default GlobalContext;

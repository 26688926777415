import React from 'react';
import styled from 'styled-components';

const CircleSpinner = styled.div`
  height: 4vw;
  width: 4vw;
  border-radius: 50%;
  border-style: solid;
  border-width: 22px;
  border-color: ${props => props.theme.colors.transparentGreen};
  border-left-color: ${props => props.theme.colors.green};
  animation: circle 1s infinite linear;
  
  @keyframes circle {
    0% {
      transform: rotate(0deg);
    }
    
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spinner = ({ className }) => <CircleSpinner className={className} />;

export default Spinner;

// Constants
import { period } from './dateConstants';

const monthNumberToName = {
  0: 'januar',
  1: 'februar',
  2: 'mart',
  3: 'april',
  4: 'maj',
  5: 'jun',
  6: 'jul',
  7: 'avgust',
  8: 'septembar',
  9: 'oktobar',
  10: 'novembar',
  11: 'decembar',
};

export function createDateFromString(date) {
  const newDate = new Date(date);

  if (newDate instanceof Date && !isNaN(newDate)) {
    return newDate;
  }

  throw new TypeError('Date is not valid!');
}

export function dateSince(date) {
  const dateToCheck = createDateFromString(date);

  const seconds = Math.floor((Date.now() - dateToCheck) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return {
      value: interval,
      type: period.YEAR,
    };
  }

  interval = Math.floor(seconds / 2592000);

  if (interval >= 1) {
    return {
      value: interval,
      type: period.MONTH,
    };
  }

  interval = Math.floor(seconds / 86400);

  if (interval >= 1) {
    return {
      value: interval,
      type: period.DAY,
    };
  }

  interval = Math.floor(seconds / 3600);

  if (interval >= 1) {
    return {
      value: interval,
      type: period.HOUR,
    };
  }

  interval = Math.floor(seconds / 60);

  if (interval >= 1) {
    return {
      value: interval,
      type: period.MINUTE,
    };
  }

  return {
    value: Math.floor(seconds),
    type: period.SECOND,
  };
}

export function dateSinceMessage(date) {
  const { value, type } = dateSince(date);

  let periodText;

  switch (type) {
    case period.YEAR:
      if (value === 1 || value === 21) {
        periodText = 'godinu';
      } else if (value === 2 || value === 3 || value === 4) {
        periodText = 'godine';
      } else {
        periodText = 'godina';
      }
      break;
    case period.MONTH:
      if (value === 1) {
        periodText = 'mesec';
      } else if (value === 2 || value === 3 || value === 4) {
        periodText = 'meseca';
      } else {
        periodText = 'meseci';
      }
      break;
    case period.DAY:
      if (value === 1 || value === 21 || value === 31) {
        periodText = 'dan';
      } else {
        periodText = 'dana';
      }
      break;
    case period.HOUR:
      if (value === 1 || value === 21) {
        periodText = 'sat';
      } else if (value === 2 || value === 3 || value === 4 || value === 22 || value === 23 || value === 24) {
        periodText = 'sata';
      } else {
        periodText = 'sati';
      }
      break;
    case period.MINUTE:
      if (value === 1 || value === 21 || value === 31 || value === 41 || value === 51) {
        periodText = 'minut';
      } else {
        periodText = 'minuta';
      }
      break;
    case period.SECOND:
      if (value === 1 || value === 21 || value === 31 || value === 41 || value === 51) {
        periodText = 'sekunda';
      } else {
        periodText = 'sekundi';
      }
      break;
    default:
      periodText = '';
  }

  return `pre ${value} ${periodText}`;
}


export function formatStringDate(stringDate) {
  const date = createDateFromString(stringDate);

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  let monthName = monthNumberToName[month];
  monthName = monthName ? monthName : 'januar';

  return `${day}. ${monthName} ${year}`;
}


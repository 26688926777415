import React from 'react';
import styled from 'styled-components';

// Components
import { LargeCaption, Description, SimpleHeaderContent } from '../../Styled';

// Utils
import { TEXT_ABOUT_ORGANIZATION } from '../../../utils/constants';
import { media } from '../../../utils/style/media';
import { getMessage } from '../../../i18n/i18n';

const ArticleBlogMotto = styled(LargeCaption)`
  font-size: 30px;

  ${media.customUp(800)`
    font-size: 40px;
  `};
`;

const ArticleBlogHeaderContent = styled(SimpleHeaderContent)`
  ${media.customUp(1900)`
    margin-left: -250px;
    `};
`;

function ArticleBlogContent() {
  return (
    <ArticleBlogHeaderContent>
      <ArticleBlogMotto>
        {getMessage('lokal.header.article-blog.motto')}
      </ArticleBlogMotto>
      <Description>{TEXT_ABOUT_ORGANIZATION}</Description>
    </ArticleBlogHeaderContent>
  );
}

export default ArticleBlogContent;

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';
import _map from 'lodash/map';

// Component
// import { OrangeButton } from './../../Styled'; // Commented 'Pridruzi se' button, currently without any function.
import { ReactComponent as SmallLogo } from '../../../assets/images/logo-organization-small.svg';

// Utils
import { media } from '../../../utils/style/media';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: ${prop('theme.navbar.height')};
  background-color: ${prop('theme.colors.white')};

  ${media.phoneUp`
    padding: ${prop('theme.page.mediumPadding')};
  `};

  ${media.customUp(1100)`
    padding: ${prop('theme.page.padding')};
  `};
`;

const styleSelectedLink = css`
  color: ${prop('theme.colors.orange')};
  border-bottom: 4px solid ${prop('theme.colors.orange')};
`;

const styleHoverLink = css`
  color: ${prop('theme.colors.black')};
  border-bottom: 4px solid ${prop('theme.colors.orange')};
`;

const StyledNavLink = styled(NavLink)`
  text-transform: uppercase;
  text-decoration: none;
  color: ${prop('theme.colors.gray169')};
  font-size: 13px;
  margin-right: 10px;
  border-bottom: 2px solid transparent;

  &.active {
    ${styleSelectedLink};
  }

  &:hover {
    ${styleHoverLink};
  }

  ${media.phoneUp`
  margin-right: 5px;
  `};

  ${media.customUp(700)`
  margin-right: 10px;
  font-size: 16px;
  `};

  ${media.tabletPortraitUp`
  margin-right: 40px;
  font-size: 1.1rem;
  `};
`;

const LogoBox = styled.div`
  width: 50px;
  display: flex;
  justify-content: center;

  ${media.tabletPortraitUp`
  display: none;
  `};
`;

// Commented 'Pridruzi se' button, currently without any function.

// const JoinAsButton = styled(OrangeButton)`
//   ${media.tabletPortraitUp`
//   width: 180px;
//     `};

// span {
//   display: none;

//   ${media.tabletPortraitUp`
//   display: block;
//   font-size: 24px;
//   margin: 0px 0px 4px 10px;
//   `};
// }
// `;

const NormalNavBar = ({ navBarData }) => {
  const { pathname } = useLocation();

  return (
    <Container>
      <LogoBox>
        <SmallLogo />
      </LogoBox>
      <nav>
        {_map(navBarData, ({ name, path }) => (
          <StyledNavLink strict key={path} pathname={pathname} name={name} to={path}>
            {name}
          </StyledNavLink>
        ))}
      </nav>
      {/* <JoinAsButton>
        Pridruži se <span> &#10141; </span> // Commented 'Pridruzi se' button, currently without any function.
      </JoinAsButton> */}
    </Container>
  );
};

export default NormalNavBar;

import React from 'react';
import styled from 'styled-components';

// Components
import { BaseInput } from './styled';

const Container = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  ${BaseInput};
`;

const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: 3px;
  left: 5px;
`;

const Input = ({ className, autoComplete, placeHolder, input, meta }) => {
  const { valid, touched, error } = meta;

  const isValid = !touched || valid;
  const title = isValid ? '' : error;

  return (
    <Container>
      <StyledInput
        title={title}
        className={className}
        valid={isValid}
        placeholder={placeHolder}
        autoComplete={autoComplete}
        {...input}
      />

      {touched && error && <ErrorText> {error} </ErrorText>}
    </Container>
  );
};

export default Input;

const get = async (url) => {
    try {
        const response = await fetch(url);

        if (!response.ok) {
            return rejectFetch(url);
        }

        return await response.json();
    } catch (e) {
      return rejectFetch(url);
    }
};

const rejectFetch = (url) => Promise.reject(`Can't fetch data from ${url}`);

export default {
    get,
}
import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { prop } from 'styled-tools';

// Components
import CommentForm from './comments/CommentForm';
import CommentList from './comments/CommentList';
import ArticleContent from './content/ArticleContent';
import ShareArticle from './ShareArticle';

// Utils
import { getImage } from '../../utils/images/getImage.js';
import { media } from '../../utils/style/media';

// Hooks
import { useArticle } from '../../hooks/useArticle.jsx';

const ContentContainer = styled.div`
  font-family: 'Muli Regular', serif;
  margin: 0 auto;
  width: 85vw;

  ${media.customUp('400')`
    width: 350px;  
  `};

  ${media.customUp('500')`
    width: 450px; 
  `};

  ${media.phoneUp`
    width: 550px; 
  `};

  ${media.tabletPortraitUp`
    width: 800px; 
  `};

  ${media.tabletLandscapeUp`
    width: 1000px; 
  `};
`;

const Image = styled.div`
  width: 100%;
  height: 200px;
  margin: 30px auto;
  display: block;
  background-size: 100% 200px;
  border-radius: 10px;

  background: url(${(props) => props.image350}) no-repeat center;

  ${media.customUp('500')`
    background: url(${(props) => props.image450}) no-repeat center;
  `};

  ${media.phoneUp`
    background: url(${(props) => props.image550}) no-repeat center;
  `};

  ${media.tabletPortraitUp`
    background: url(${(props) => props.image800}) no-repeat center;
  `};

  ${media.tabletLandscapeUp`
    background: url(${(props) => props.image1000}) no-repeat center;
  `};
`;

const ImageSource = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5px;
  color: ${prop('theme.colors.white')};
  font-size: 0.7rem;
`;

const Article = ({ match }) => {
  const { newsArticle } = useArticle();
  const { content, images, title, id, comments, imageSource } = newsArticle;

  const { hostname } = window.location;

  const ogDescription = title;
  const fullHostName = `https://${hostname}`;

  console.log(window.location.href);
  console.log(title);

  return (
    <div>
      <Helmet>
        <title>{`${title} - Lokalna Alternativa Vrbas`}</title>
        <meta name="description" content={ogDescription} />
        <meta property="og:title" content={title} />
        <meta property="og:url" content={`${fullHostName}${match.url}`} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          itemProp="image"
          content={images && getImage(images, 'OG')}
        />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={ogDescription} />
        <meta
          property="twitter:image"
          content={images && getImage(images, 'OG')}
        />
      </Helmet>

      <ContentContainer>
        {images && (
          <Image
            image350={getImage(images, 'ARTICLE_350')}
            image450={getImage(images, 'ARTICLE_450')}
            image550={getImage(images, 'ARTICLE_550')}
            image800={getImage(images, 'ARTICLE_800')}
            image1000={getImage(images, 'ARTICLE_1000')}
          >
            {imageSource && (
              <ImageSource>{`Izvor: ${imageSource}`}</ImageSource>
            )}
          </Image>
        )}
        <div>
          <ArticleContent content={content} />

          {title && <ShareArticle url={window.location.href} title={title} />}
          <CommentForm articleId={id} />
          <CommentList comments={comments} />
        </div>
      </ContentContainer>
    </div>
  );
};

export default Article;

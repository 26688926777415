import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

// Components
import ArticleBlogItem from '../../components/article/ArticleBlogItem';

// Utils
import { media } from '../../utils/style/media';

// Actions
import { fetchBlogArticles } from '../../commands/article/actions';

const Title = styled.p`
  width: 100%;
  margin: 20px 0 10px 0;
  font-size: 2em;
  font-family: 'Roboto Bold', serif;
`;

const Container = styled.section`
  display: grid; 
  grid-template-columns: 90vw; 
  grid-template-rows: 100px 90vw; 
  grid-auto-rows: 90vw;
  grid-row-gap: 20px;
  grid-column-gap: 20px; 
  justify-content: center;
  justify-items: center;
  margin-bottom: 20px;

  ${Title} {
  display: none;
    }
    
  ${media.phoneUp`
    grid-template-columns: 45vw 45vw;
    grid-template-rows: 100px 45vw; 
    grid-auto-rows: 45vw;

    ${Title} {
      display: block;
      grid-column: 1 / 1;
      width: 100%;
    }
  `}

  ${media.customUp(800)`
    grid-template-columns: repeat(2, 350px);
    grid-template-rows: 100px 350px; 
    grid-auto-rows: 350px;

    ${Title} {
      grid-column: 1 / 1;
    }
  `}

  ${media.tabletLandscapeUp`
    grid-template-columns: repeat(3, 350px);
    grid-template-rows: 100px 350px; 
    grid-auto-rows: 350px;

    ${Title} {
      grid-column: 1 / 3;
    }
  `}

  ${media.customUp(1500)`
    grid-template-columns: repeat(4, 350px);
    grid-template-rows: 100px 350px; 
    grid-auto-rows: 350px;

    ${Title} {
      grid-column: 1 / 4;
    } 
  `}
`;

const FirstGridColumn = styled.div`
  height: 100px;
  grid-column: 1 / -1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
`;

function ArticleBlog() {
  const [articles, setArticles] = useState([]);

  const getArticles = async () => {
    const fetchedArticles = await fetchBlogArticles();
    setArticles(fetchedArticles);
  };

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Lokalna Alternativa Vrbas</title>
      </Helmet>
      <FirstGridColumn>
        <Title> Vesti </Title>
      </FirstGridColumn>

      {articles.map((article) => (
        <ArticleBlogItem key={article.id} article={article} />
      ))}
    </Container>
  );
}

export default ArticleBlog;

import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

// Components
import FooterNavBar from './FooterNavBar';
import FooterRights from './FooterRights';

// Assets
import { ReactComponent as FooterArrow } from '../../assets/images/footer-arrow.svg';

// Utils
import { media } from '../../utils/style/media';

const Container = styled.footer`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  align-items: center;
  justify-items: center;
  color: ${prop('theme.colors.white')};
  background: ${prop('theme.colors.dark')};
  padding: ${prop('theme.page.mediumPadding')};
  padding-bottom: 15px;
  font-size: 1rem;

  div:first-child {
    order: 1;
  }

  div:last-child {
    display: none;
  }

  ${media.tabletPortraitUp`
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto;
    
    div:first-child {
      order: 0;
    } 
    
    div:last-child {
      display: block;
    } 
    
    padding-bottom: 0;
  `};

  ${media.customUp(1100)`
    padding: ${prop('theme.page.padding')};
  `};
`;

const StyledFooterArrow = styled(FooterArrow)`
  width: 50px;
  height: 50px;
  margin: 15px 10px;
  cursor: pointer;
`;

const Footer = () => (
  <Container>
    <FooterRights />
    <StyledFooterArrow onClick={() => window.scrollTo(0, 0)} />
    <FooterNavBar />
  </Container>
);

export default Footer;

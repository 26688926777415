import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

// Assets
import headerBackgroundImg from '../../assets/images/header.png';
import { ReactComponent as LogoMotto } from '../../assets/images/logo-motto.svg';
import { ReactComponent as LargeOrganizationLogo } from '../../assets/images/logo-organization-large.svg';

// Components
import HeaderContent from './HeaderContent';

// Utils
import { media } from '../../utils/style/media';

const Container = styled.header`
  display: flex;
  align-items: center;
  margin-top: ${prop('theme.navbar.smallHeight')};
  background: url(${headerBackgroundImg}) no-repeat center;
  background-size: cover;
  box-sizing: border-box;
  padding: ${prop('theme.page.mediumPadding')};

  position: relative;
  height: 200px;

  ${media.phoneUp`
    margin-top: ${prop('theme.navbar.height')};
  `};

  ${media.customUp(1100)`
    padding: ${prop('theme.page.padding')};
    height: 300px;
  `};
`;

const TextContainer = styled.div`
  color: ${(props) => props.theme.colors.white};
  align-items: center;
  z-index: 2;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoMottoContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  overflow: hidden;

  ${media.phoneUp`
    position: unset;
    `};

  ${media.customUp(800)`
    overflow: unset;
    margin-right: 0;
  `};
`;

const LogoMottoStyled = styled(LogoMotto)`
  height: 160px;
  width: 160px;
  margin-right: -20px;
  margin-bottom: 10px;
  opacity: 0.2;
  transform: rotate(-30deg);

  ${media.phoneUp`
    margin-left: auto;
    margin-right: 20px; 
    opacity: 0.5;
  `};

  ${media.customUp(1100)`
    height: 200px;
    width: 200px;
  `};
`;

const LogoOrganizationContainer = styled.div`
  align-self: center;
  margin-right: 10px;
  display: none;

  ${media.tabletPortraitUp`
  display: block;
  
  `};

  ${media.tabletLandscapeUp`
    margin-right: 30px;
  `};
`;

const LogoOrganizationStyled = styled(LargeOrganizationLogo)`
  ${media.customUp(800)`
  width: 120px;
  margin-right: 10px;
  `};

  ${media.customUp(1100)`
  width: 130px;
  margin-right: 10px;
  `};
`;

const SimpleHeader = () => {
  return (
    <Container>
      <LogoOrganizationContainer>
        <LogoOrganizationStyled />
      </LogoOrganizationContainer>
      <TextContainer>
        <HeaderContent />
      </TextContainer>
      <LogoMottoContainer>
        <LogoMottoStyled />
      </LogoMottoContainer>
    </Container>
  );
};

export default SimpleHeader;

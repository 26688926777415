import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { getImage } from '../../utils/images/getImage.js';

// Components
import ArticleBlogInfo from './ArticleBlogInfo.jsx';

// Data
import { categoryOptions } from '../../data/categoryOptions';

// Utils
import { getMessage } from '../../i18n/i18n';
import paths from './../../utils/paths/paths';
import { media } from './../../utils/style/media';
import { theme } from './../../utils/style/theme';

const ArticleWrapper = styled.div`
  width: 100%;
  background: url(${(props) => props.blogImage}) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;

  ${media.phoneUp`
    :first-of-type { 
      grid-column: 1 / 3;
      background: url(${(props) => props.mainImage}) no-repeat center;
      background-size: cover;
    } 
  `};
`;

const ArticleCategory = styled.div`
  margin: 18px auto 0px 18px;
  padding: 12px;
  border-radius: 5px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 75%;
  font-family: 'Roboto Medium', serif;

  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ backgroundColor }) =>
    backgroundColor === `${theme.colors.white}` &&
    `opacity: 0.8; font-weight:700;`};
`;

const ArticleBlogItem = ({
  article: { category, title, date, images, slug },
}) => {
  const { textKey, color, backgroundColor } = categoryOptions[category];

  const history = useHistory();
  const url = `${paths.articleBlog}/${slug}`;

  return (
    <ArticleWrapper
      blogImage={getImage(images, 'BLOG')}
      mainImage={getImage(images, 'BLOG_MAIN')}
      onClick={() => history.push(url)}
    >
      <ArticleCategory backgroundColor={backgroundColor} color={color}>
        {getMessage(textKey)}
      </ArticleCategory>
      <ArticleBlogInfo title={title} date={date} />
    </ArticleWrapper>
  );
};

export default ArticleBlogItem;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;

  p {
    margin: 5px;
  }
`;

const FooterRights = () => (
  <Container>
    <p>&copy; Sva prava zadržava</p>
    <p>LOKALNA ALTERNATIVA</p>
  </Container>
);

export default FooterRights;

const textKeys = {
  'lokal.article.category.vrbas': 'Opština Vrbas',
  'lokal.article.category.province': 'Pokrajina',
  'lokal.article.category.republic': 'Republika',
  'lokal.article.category.region': 'Region',
  'lokal.header.name': 'Lokalna alternativa Vrbas',
  'lokal.header.home.motto.one': 'Budi slagalica koja nedostaje!',
  'lokal.header.home.motto.two': 'Zajedno do promena!',
  'lokal.header.article-blog.motto': 'Budite obavešteni o svemu!',
};

export const getMessage = (key: String): String => {
  const message = textKeys[key];

  if (!message) {
    throw new Error(`Not valid text key: ${key}`);
  }

  return message;
};

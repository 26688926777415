import { css } from 'styled-components';
import { prop } from 'styled-tools';

const customUp = width => (...args) => css`
  @media (min-width:  ${width}px) {
    ${css(...args)};
  }
`;

const phoneUp = (...args) => css`
  @media (min-width: ${prop('theme.screenSize.small')}) {
    ${css(...args)};
  }
`;

const tabletPortraitUp = (...args) => css`
  @media (min-width: ${prop('theme.screenSize.medium')}) {
    ${css(...args)};
  }
`;

const tabletLandscapeUp = (...args) => css`
  @media (min-width: ${prop('theme.screenSize.large')}) {
    ${css(...args)};
  }
`;

export const media = {
  customUp,
  phoneUp,
  tabletPortraitUp,
  tabletLandscapeUp,
};

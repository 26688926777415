export const getImage = (images, type) => {
  let matchedImageName;

  images.forEach((img) => {
    if (img.type === type) {
      matchedImageName = img.name;
    }
  });

  return `${process.env.PUBLIC_URL}/assets/images/${matchedImageName}`;
};

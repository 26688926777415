import React, { useEffect } from 'react';
import styled from 'styled-components';

// Components
import {
  SmallCaption,
  LargeCaption,
  Circle,
  SimpleHeaderContent,
} from './../../Styled';

// Data
import { categoryOptions } from '../../../data/categoryOptions';

// Utils
import { media } from '../../../utils/style/media';
import { getMessage } from '../../../i18n/i18n';
import { formatStringDate } from '../../../utils/date/dateUtils';

// Hooks
import { useArticle } from '../../../hooks/useArticle.jsx';

const CategoryAndDateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Date = styled(SmallCaption)`
  font-size: 10px;

  ${media.phoneUp`
    font-size: 13px;
  `};

  ${media.tabletPortraitUp`
    font-size: 15px;
  `};

  ${media.tabletLandscapeUp`
    font-size: 17px;
  `};
`;

const Category = styled(Date)`
  display: none;

  ${media.customUp(340)`
    display: flex;
  `};
`;

const ArticleTitle = styled(LargeCaption)`
  font-size: 22px;

  ${media.customUp(400)`
    font-size: 24px;
  `};

  ${media.phoneUp`
    font-size: 28px; 
  `};

  ${media.tabletPortraitUp`
    font-size: 30px;
  `};

  ${media.customUp(1000)`
    font-size: 33px;
  `};

  ${media.tabletLandscapeUp`
    font-size: 40px;
  `};
`;

const CategoryAndDateCircle = styled(Circle)`
  background-color: ${(props) => props.theme.colors.white};
  width: 5px;
  height: 5px;

  display: none;

  ${media.customUp(340)`
  display: flex;
`};
`;

function ArticleItemContent({ articleId }) {
  const { getArticle, newsArticle } = useArticle();
  const { title, category, date } = newsArticle;

  const textKey = category && categoryOptions[category].textKey;

  useEffect(
    () => {
      getArticle(articleId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <SimpleHeaderContent>
      <CategoryAndDateWrapper>
        <Category> {textKey && getMessage(textKey)} </Category>
        <CategoryAndDateCircle />
        <Date> {date && formatStringDate(date)} </Date>
      </CategoryAndDateWrapper>

      <ArticleTitle>{title}</ArticleTitle>
    </SimpleHeaderContent>
  );
}

export default ArticleItemContent;
